import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220714" />
      <p>I fell in love with some knit when visited a butique store today but still thinking to buy or not, that gonna make me a bankrupt (the common issue for every autumn coming).</p>
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220714.jpg"
          width={600}
          quality={95}
          />
      </div>
      <p>After left the store, went to a coffee stand and had lunch there, a cup of latte and a meat pie. When I finished eating and was drawing on notebook, some korean tourists entered the cafe. I tried to listen to the conversation and could pick some words. (And also today I could read some Hangul of some restaurant's name, which made me motivated to study.)</p>
      <div className="text-center"><iframe src="https://assets.pinterest.com/ext/embed.html?id=94997873382347328" height="547" width="345" frameborder="0" scrolling="no" ></iframe></div>
      <p>I met up with my mom and when walking in the street, happened to find a gallery that held the exhibition of Bernard Buffet, so we dropped by. I liked his series of paintings of flowers with yellow background (especially the calla lily one).</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
